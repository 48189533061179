import { FC } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Body } from '@sumup/circuit-ui';

import { Anchor } from 'src/experiments/odl/components/Anchor';
import { dispatchSkipShopEvent } from 'shared/services/tracker/events';

const Container = styled.div(
  ({ theme }) => css`
    // set z-index to default so the shadows from shop header could show up
    position: relative;
    z-index: ${theme.zIndex.default};
    display: flex;
    align-items: center;
    border-bottom: ${theme.borderWidth.kilo} solid var(--cui-border-normal);
    padding: calc(${theme.spacings.giga} - ${theme.spacings.kilo}) 0
      ${theme.spacings.giga} 0;
    margin: 0 ${theme.spacings.kilo} 0 0;

    ${theme.mq.megaToGiga} {
      border-bottom: none;
      padding: 0;
      margin-right: ${theme.spacings.kilo};
      margin-left: -${theme.spacings.kilo};
    }

    ${theme.mq.giga} {
      border-bottom: none;
      padding: 0;
      margin-right: ${theme.spacings.kilo};
      margin-left: -${theme.spacings.kilo};
    }
  `,
);

const StyledAnchor = styled(Anchor)<{ isODLExperimentEnabled?: boolean }>(
  ({ theme, isODLExperimentEnabled }) => css`
    font-weight: ${theme.fontWeight.regular};
    text-decoration: none;
    margin-left: ${theme.spacings.bit};
    font-size: ${theme.typography.body.one.fontSize};
    line-height: ${theme.typography.body.one.lineHeight};
    ${!isODLExperimentEnabled && `color: var(--cui-fg-accent);`}

    :hover {
      text-decoration: underline;
    }
  `,
);

export interface HaveAReaderBarProps {
  message: string | undefined;
  actionTitle: string | undefined;
  actionUrl: string | undefined;
  isODLExperimentEnabled?: boolean;
}

/**
 * HaveAReaderBar shows under the shop header. It contains a message
 * to indicate that who should pay attention here and an action link
 * to guide the next step.
 */
export const HaveAReaderBar: FC<HaveAReaderBarProps> = ({
  message = 'Already have a reader?',
  actionTitle = 'Sign up',
  actionUrl = '',
  isODLExperimentEnabled = false,
}: HaveAReaderBarProps) => (
  <Container data-testid="skip_shop-nav-bar">
    <Body size="one">
      {message}
      <StyledAnchor
        isODLExperimentEnabled={isODLExperimentEnabled}
        size="one"
        href={actionUrl}
        variant="highlight"
        onClick={() => dispatchSkipShopEvent()}
      >
        {actionTitle}
      </StyledAnchor>
    </Body>
  </Container>
);
