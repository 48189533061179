import { css } from '@emotion/react';
import { useMemo, FC, ChangeEvent } from 'react';
import styled from '@emotion/styled';
import { Select } from '@sumup/circuit-ui';

import FlagIcon from './FlagIcon';

import { getCountryCodeFromISOLocale } from 'shared/i18n/helpers';

export const StyledFlagIcon = styled(FlagIcon)(
  () => css`
    display: block;
    position: absolute;
    margin: 10px 0 10px 12px;
    pointer-events: none;
    z-index: 40;
  `,
);

export const CountrySelect = styled(Select)<{ isFullWidth?: boolean }>(
  ({ theme, isFullWidth }) => css`
    margin-right: ${theme.spacings.mega};
    ${isFullWidth && `width: 100%`}
  `,
);

const StyledDiv = styled('div')(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: ${theme.spacings.mega};
    ${theme.mq.mega} {
      margin: 0;
      justify-content: flex-end;
    }
  `,
);

export interface ICountryLanguage {
  code: string;
  name: string;
}
export interface ICountryWithLocale {
  /** Country ISO code */
  code: string;

  /** Name of the country */
  name: string;

  /** ISO of default country language */
  defaultLanguageCode: string;

  /** List of available languages for country */
  availableLanguages: ICountryLanguage[];
}

export interface ILocaleDropdownProps {
  countries: ICountryWithLocale[];
  currentLocale: string;
  countryLabel?: string;
  languageLabel?: string;
  onLocaleChange: (localeCode: string) => unknown;
  onCountryChange: (countryCode: string) => unknown;
}

export const LocaleDropdown: FC<ILocaleDropdownProps> = ({
  countries,
  onCountryChange,
  onLocaleChange,
  currentLocale,
  countryLabel = 'Select your country',
  languageLabel = 'Select your language',
}) => {
  const currentCountry = useMemo(
    () =>
      countries.find(
        (country) =>
          country.code === getCountryCodeFromISOLocale(currentLocale),
      ),
    [currentLocale, countries],
  );

  const countryOptions = useMemo(
    () =>
      countries.map((country) => ({
        label: country.name,
        value: country.code,
      })),
    [countries],
  );

  const languageOptions = useMemo(
    () =>
      currentCountry?.availableLanguages.map((locale) => ({
        label: locale.name,
        value: `${locale.code}-${currentCountry.code}`,
      })),
    [currentCountry],
  );

  const handleSelectCountry = (e: ChangeEvent<HTMLSelectElement>) => {
    onCountryChange(e.currentTarget.value);
  };

  const handleSelectLanguage = (e: ChangeEvent<HTMLSelectElement>) => {
    onLocaleChange(e.currentTarget.value);
  };

  const shouldShowLanguageSelection =
    currentCountry?.availableLanguages.length > 1;

  if (!currentCountry) {
    return null;
  }

  /**
   * FIXME: Current Select dropdown is too big for our design.
   * Please change size to 'kilo' when circuit UI implement it.
   */
  return (
    <StyledDiv>
      <CountrySelect
        isFullWidth={!shouldShowLanguageSelection}
        label={countryLabel}
        value={currentCountry.code}
        onChange={handleSelectCountry}
        options={countryOptions}
        data-testid={'country-select'}
        hideLabel
        renderPrefix={() => (
          <StyledFlagIcon countryCode={currentCountry.code} />
        )}
      />
      {shouldShowLanguageSelection && (
        <Select
          label={languageLabel}
          value={currentLocale}
          onChange={handleSelectLanguage}
          options={languageOptions}
          hideLabel
          data-testid={'language-select'}
        />
      )}
    </StyledDiv>
  );
};
