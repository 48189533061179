import {
  FC,
  useState,
  createContext,
  useEffect,
  ReactNode,
  useCallback,
  useContext,
  ComponentType,
  Component as ReactComponent,
} from 'react';
import { NotificationInline, Grid } from '@sumup/circuit-ui';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

const StyledDiv = styled('div')<{ sendToTop?: boolean }>(
  ({ theme, sendToTop }) => css`
    position: fixed;
    top: ${sendToTop ? theme.spacings.mega : '10px'};
    width: 100%;
    z-index: ${theme.zIndex.absolute} !important;
    left: 0;

    ${theme.mq.untilMega} {
      left: 50%;
      transform: translateX(-50%);
    }

    ${theme.mq.giga} {
      top: ${sendToTop ? theme.spacings.giga : '10px'};
    }
  `,
);

export interface INotificationProviderProps {
  children?: ReactNode;
  message: string | ReactNode;
  variant: 'info' | 'alert' | 'confirm' | 'notify';
  sendToTop?: boolean;
  closeButtonLabel?: string;
}

/** 10 sec */
const NOTIFICATION_DEFAULT_DURATION = 10000;

interface INotificationOptions
  extends Omit<INotificationProviderProps, 'children'> {
  title?: string;
}
export interface INotificationContext {
  isNotificationVisible: boolean;
  setNotification: (value: boolean) => void;
  displayNotification: (options: INotificationOptions) => void;
}

export const NotificationContext = createContext<INotificationContext>({
  isNotificationVisible: false,
  setNotification: () => {},
  displayNotification: () => {},
});

export const NotificationProvider: FC<INotificationProviderProps> = ({
  children,
  variant,
  message,
  sendToTop = false,
  closeButtonLabel = 'Close',
}) => {
  const [isNotificationVisible, setNotification] = useState(false);
  const [notificationOptions, setNotificationOptions] =
    useState<INotificationOptions>({
      variant,
      message,
      sendToTop,
      title: null,
    });

  const displayNotification = useCallback((options) => {
    setNotificationOptions(options);
    setNotification(true);
  }, []);

  useEffect(() => {
    let notificationTimer;
    if (isNotificationVisible) {
      notificationTimer = setTimeout(() => {
        setNotification(false);
      }, NOTIFICATION_DEFAULT_DURATION);
    }
    return () => {
      clearTimeout(notificationTimer);
    };
  }, [isNotificationVisible]);

  const closeNotification = useCallback(() => {
    setNotification(false);
  }, []);

  return (
    <NotificationContext.Provider
      value={{ isNotificationVisible, setNotification, displayNotification }}
    >
      {children}

      <StyledDiv
        sendToTop={notificationOptions.sendToTop}
        data-testid="global-notification--error"
      >
        <Grid>
          <NotificationInline
            body={
              (notificationOptions.message ||
                notificationOptions.title) as string
            }
            headline={
              notificationOptions.title && {
                label: notificationOptions.title,
                as: 'h4',
              }
            }
            variant={notificationOptions.variant}
            isVisible={isNotificationVisible}
            onClose={closeNotification}
            closeButtonLabel={closeButtonLabel}
          />
        </Grid>
      </StyledDiv>
    </NotificationContext.Provider>
  );
};

// TODO refactor all independent uses of <NotificationProvider />
export const withNotifications = <P extends {}>(
  Component: ComponentType<P>,
): ComponentType<P> =>
  class WithNotification extends ReactComponent<P> {
    render() {
      return (
        <NotificationProvider message="" variant="confirm">
          <Component {...(this.props as P)} />
        </NotificationProvider>
      );
    }
  };

export const useNotifications = (): INotificationContext =>
  useContext<INotificationContext>(NotificationContext);
