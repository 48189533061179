import { FC, memo } from 'react';

import { Icon } from '../Icon';

interface Props {
  countryCode: string;
}

const FlagIcon: FC<Props> = ({ countryCode, ...rest }) => {
  if (!countryCode) {
    return null;
  }

  const url = `https://circuit.sumup.com/icons/v1/flag_${countryCode.toLowerCase()}_small.svg`;

  return <Icon src={url} {...rest} />;
};

const propsAreEqual = (prevProps: Props, nextProps: Props) =>
  prevProps.countryCode === nextProps.countryCode;

export default memo(FlagIcon, propsAreEqual);
