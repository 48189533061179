/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { FC } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Grid } from '@sumup/circuit-ui';
import { useRouter } from 'next/router';

import { LocaleDropdown } from '../LocaleDropdown';
import { ILocaleDropdownProps } from '../LocaleDropdown/LocaleDropdown';

import { useTypedSelector } from 'shared/store';
import { HaveAReaderBar } from 'productSelection/components/HaveAReaderBar';
import { IFooterFields } from 'shared/infra/contentful/contentful';
import { useShopExperience } from 'shared/context';
import { ShopExperience } from 'shared/constants/ShopExperience';
import {
  showCookieConsentPreferences,
  useCookieConsent,
} from 'utils/scripts/cookieConsent/cookieConsent';
import { Anchor } from 'src/experiments/odl/components/Anchor';
import {
  ODL_EXPERIMENT,
  VARIATION,
} from 'shared/services/optimizely/constants';

/**
 * These offset variables should cover most of the cases
 * for the dropdowns to be displayed properly, as we have
 * shop cart widget also fixed to bottom
 */
const DESKTOP_BOTTOM_OFFSET_FOR_PDP_CART_BAR = 82;
const DESKTOP_BOTTOM_OFFSET_FOR_CART_BAR = 76;
const DESKTOP_TOP_OFFSET_FOR_PRODUCTS = 106;
const MOBILE_BOTTOM_OFFSET_FOR_CART = 92;

const CookiePreferenceContainer = styled.div<{
  isProductSelectionPage?: boolean;
}>(
  ({ theme, isProductSelectionPage }) => css`
    position: relative;
    display: flex;
    align-items: center;
    ${isProductSelectionPage &&
    `border-bottom: ${theme.borderWidth.kilo} solid var(--cui-border-normal);`}
    padding: ${theme.spacings.giga} 0;
    margin: 0 ${theme.spacings.kilo} 0 0;

    ${theme.mq.mega} {
      padding: 0;
      border-left: ${theme.borderWidth.kilo} solid var(--cui-border-normal);
      border-bottom: none;
      ${isProductSelectionPage
        ? `padding: 0 0 0 ${theme.spacings.kilo};`
        : `margin: 0 0 0 -${theme.spacings.kilo}; border: none;`}
    }

    ${theme.mq.tera} {
      ${!isProductSelectionPage && `padding: 0;`}
    }
  `,
);

const StyledFooter = styled.footer<{
  isInExperiment?: boolean;
  useCartSpacing?: boolean;
  isReducedPdpExpEnabled?: boolean;
  isProductDetailsPage?: boolean;
  showTransparentPricing?: boolean;
}>(
  ({
    theme,
    useCartSpacing,
    isInExperiment = false,
    isReducedPdpExpEnabled = false,
    isProductDetailsPage = false,
  }) => css`
    background-color: var(--cui-bg-subtle);
    padding: ${theme.spacings.mega};
    ${useCartSpacing && `padding-bottom: ${MOBILE_BOTTOM_OFFSET_FOR_CART}px;`}
    z-index: calc(${theme.zIndex.input} + 1);
    width: 100%;

    ${theme.mq.mega} {
      padding: ${theme.spacings.mega} 0;
      bottom: 0;
      left: 0;
      width: 100%;

      ${isProductDetailsPage &&
      useCartSpacing &&
      `margin-bottom: ${DESKTOP_BOTTOM_OFFSET_FOR_PDP_CART_BAR}px;`}

      ${useCartSpacing &&
      !isInExperiment &&
      `margin-bottom: ${DESKTOP_BOTTOM_OFFSET_FOR_CART_BAR}px;
      `}

      ${isProductDetailsPage &&
      isReducedPdpExpEnabled &&
      `margin-bottom: ${DESKTOP_BOTTOM_OFFSET_FOR_PDP_CART_BAR}px;
      `}
    }

    ${theme.mq.megaToGiga} {
      padding-left: ${theme.spacings.mega};
    }

    ${theme.mq.tera} {
      ${isProductDetailsPage &&
      !isReducedPdpExpEnabled &&
      `
      position: relative;
      ${
        useCartSpacing &&
        `margin-bottom: ${DESKTOP_BOTTOM_OFFSET_FOR_CART_BAR}px;
        `
      }
      bottom: 0;
      `}
    }
  `,
);

const StyledGrid = styled(Grid)(
  () =>
    css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
    `,
);

const StyledCol = styled('div')(
  ({ theme }) => css`
    display: block;
    width: 100%;
    ${theme.mq.mega} {
      width: auto;
      display: flex;
      align-items: center;
    }
  `,
);

const SpacingDiv = styled('div')(
  ({ theme }) => `
  margin-top: ${DESKTOP_TOP_OFFSET_FOR_PRODUCTS}px;
  display: none;

  ${theme.mq.mega} {
    display: block;
  }
`,
);

const StyledAnchor = styled(Anchor)(
  ({ theme }) => `
  text-decoration: none;
  color: var(--cui-fg-normal);
  font-size: ${theme.typography.body.one.fontSize};
  line-height: ${theme.typography.body.one.lineHeight};
  color: var(--cui-border-accent);
  :hover {
    text-decoration: underline;
  }
  `,
);

export interface IFooterProps {
  localeDropdownProps?: ILocaleDropdownProps;
  footerContent: IFooterFields;
  isProductSelectionPage?: boolean;
  isProductDetailsPage?: boolean;
  isFromProductCTA?: boolean;
  isAddressPage?: boolean;
  isReducedPdpExpEnabled?: boolean;
  message?: string;
  actionTitle?: string;
  actionUrl?: string;
  showTransparentPricing?: boolean;
  hasPDPDetails?: boolean;
  hideCart?: boolean;
}

export const Footer: FC<IFooterProps> = ({
  footerContent,
  localeDropdownProps,
  isProductSelectionPage = false,
  isProductDetailsPage = false,
  isReducedPdpExpEnabled = false,
  message,
  actionTitle,
  actionUrl,
  showTransparentPricing = false,
  hasPDPDetails = true,
  hideCart = false,
}) => {
  const { query } = useRouter();

  const isODLExperimentEnabled = query[ODL_EXPERIMENT.id] === VARIATION;
  const shopExperience = useShopExperience();
  const orderProducts = useTypedSelector((state) => state.order.products);
  const hasCartBar = isProductDetailsPage && hasPDPDetails;
  const isCartVisible = (orderProducts.length > 0 || hasCartBar) && !hideCart;
  const isDashboardShop = shopExperience === ShopExperience.DASHBOARD;
  const isProductPage = isProductSelectionPage || isProductDetailsPage;
  const useCartSpacing = isCartVisible && isProductPage;
  const { isEnabled: isCookieConsentEnabled } = useCookieConsent();
  const hideFooter = !isCookieConsentEnabled && !isProductSelectionPage;
  const shouldDisplayHaveAReader = !isDashboardShop && isProductSelectionPage;

  return hideFooter ? null : (
    <>
      {isCartVisible && !isProductPage && <SpacingDiv />}
      <StyledFooter
        useCartSpacing={useCartSpacing}
        isReducedPdpExpEnabled={isReducedPdpExpEnabled}
        isProductDetailsPage={isProductDetailsPage}
        showTransparentPricing={showTransparentPricing}
      >
        <StyledGrid>
          <StyledCol>
            {shouldDisplayHaveAReader && (
              <HaveAReaderBar
                message={message}
                actionTitle={actionTitle}
                actionUrl={actionUrl}
                isODLExperimentEnabled={isODLExperimentEnabled}
              />
            )}

            {isCookieConsentEnabled && (
              <CookiePreferenceContainer
                isProductSelectionPage={isProductSelectionPage}
              >
                <StyledAnchor onClick={showCookieConsentPreferences}>
                  {footerContent.cookiePolicy}
                </StyledAnchor>
              </CookiePreferenceContainer>
            )}
          </StyledCol>

          {isProductSelectionPage && (
            <StyledCol>
              <LocaleDropdown {...localeDropdownProps} />
            </StyledCol>
          )}
        </StyledGrid>
      </StyledFooter>
    </>
  );
};
