import styled from '@emotion/styled';
import { css } from '@emotion/react';

type IconSizeType = 'byte' | 'kilo' | 'mega' | 'giga';
/**
 * Icon component which is using external SVG files.
 * Using img to display the svg following:
 * https://vecta.io/blog/best-way-to-embed-svg/
 */
const Icon = styled('img')<{ size?: IconSizeType }>(
  ({ theme, size }) => css`
    display: inline-block;
    vertical-align: middle;
    width: ${theme.iconSizes[size]};
    height: ${theme.iconSizes[size]};
    object-fit: cover;
    position: relative;
  `,
);

Icon.defaultProps = {
  size: 'mega',
  alt: 'icon image',
};

export default Icon;
